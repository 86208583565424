<template>
    <div class="my-order">
        <transition name="slide">
            <div v-if="showModal" class="confirm-mask">
                <div class="modal">
                    <div class="modal-title">{{$t('PerpetualContract.EditOrder')}}</div>
                    <div class="info-list">
                        <div class="info-item">
                            <div class="label">{{$t('PerpetualContract.TakeProfitPrice')}}:</div>
                            <el-input-number v-model="profitPrice" size="small" :min="0" :step="10"></el-input-number>
                        </div>
                        <div class="info-item">
                            <div class="label">{{$t('PerpetualContract.StopPrice')}}:</div>
                            <el-input-number v-model="stopPrice" size="small" :min="0" :step="10"></el-input-number>
                        </div>
                    </div>
                    <div class="operate-btns">
                        <el-button class="cancel-btn" @click="closeModal">{{$t('Common.Cancel')}}</el-button>
                        <el-button class="confirm-btn" @click="editOrder">{{$t('PerpetualContract.Edit')}}</el-button>
                    </div>
                </div>
            </div>
        </transition>
        <div class="tab-list">
            <div v-for="(item,index) in tabList" 
            :key="index" 
            :class="['tab-item', curIndex == index ? 'active' : '']" 
            @click="changeCurIndex(index)">
                {{item}}
            </div>
        </div>
        <div v-if="curIndex == 0" class="record-list">
            <div v-for="(item,index) in recordList" :key="index" class="record-item">
                <div class="info-item">
                    <div class="label">{{$t('Trade.Time')}}</div>
                    <div class="value" :style="{ color: item.ai == 1 ? '#5466EF' : '' }">
                        {{item.ai == 1 ? `AI (${item.created_at})` : item.created_at}}
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Currency')}}</div>
                    <div class="value">{{item.product.name}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.type')}}</div>
                    <div class="value" :style="{ color: item.direction == '1' ? '#01BD8B' : '#FB474E' }">
                        {{item.direction == '1' ? $t('PerpetualContract.OpenLong') : $t('PerpetualContract.OpenShort')}}
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.Margin')}}</div>
                    <div class="value">{{parseFloat(item.margin)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.Lever')}}</div>
                    <div class="value">{{item.level}}X</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.HandlingFee')}}</div>
                    <div class="value">{{parseFloat(item.service_fee)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.TakeProfitPrice')}}</div>
                    <div class="value">{{parseFloat(item.max_price)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.StopPrice')}}</div>
                    <div class="value">{{parseFloat(item.stop_price)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.CurrentPrice')}}</div>
                    <div class="value">{{parseFloat(item.latest_price)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.OpeningPrice')}}</div>
                    <div class="value">{{parseFloat(item.oprice)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.UnsettledProfit')}}</div>
                    <div class="value profit" :style="{ color: parseFloat(item.unsettled_profit) > 0 ? '#01BD8B' : '#FB474E' }">
                        {{parseFloat(item.unsettled_profit)}}
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.Operate')}}</div>
                    <div class="value">
                        <div class="btns">
                            <el-button class="btn" @click="Edit(item.id,parseFloat(item.max_price),parseFloat(item.stop_price),parseFloat(item.oprice))">{{$t('PerpetualContract.Edit')}}</el-button>
                            <el-button class="btn" @click="closePosition(item.id)">{{$t('PerpetualContract.ClosePosition')}}</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="curIndex == 1" class="record-list">
            <div v-for="(item,index) in recordList" :key="index" class="record-item">
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.OpenTime')}}</div>
                    <div class="value" :style="{ color: item.ai == 1 ? '#5466EF' : '' }">
                        {{item.ai == 1 ? `AI (${item.created_at})` : item.created_at}}
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.ClosingTime')}}</div>
                    <div class="value">{{item.updated_at}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.Currency')}}</div>
                    <div class="value">{{item.product.name}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('Trade.type')}}</div>
                    <div class="value" :style="{ color: item.direction == '1' ? '#01BD8B' : '#FB474E' }">
                        {{item.direction == '1' ? $t('PerpetualContract.OpenLong') : $t('PerpetualContract.OpenShort')}}
                    </div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.Margin')}}</div>
                    <div class="value">{{parseFloat(item.margin)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.Lever')}}</div>
                    <div class="value">{{item.level}}X</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.HandlingFee')}}</div>
                    <div class="value">{{parseFloat(item.service_fee)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.TakeProfitPrice')}}</div>
                    <div class="value">{{parseFloat(item.max_price)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.StopPrice')}}</div>
                    <div class="value">{{parseFloat(item.stop_price)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.OpeningPrice')}}</div>
                    <div class="value">{{parseFloat(item.oprice)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.ClosingPrice')}}</div>
                    <div class="value">{{parseFloat(item.latest_price)}}</div>
                </div>
                <div class="info-item">
                    <div class="label">{{$t('PerpetualContract.SettlementProfit')}}</div>
                    <div class="value profit" :style="{ color: parseFloat(item.profit) > 0 ? '#01BD8B' : '#FB474E' }">
                        {{parseFloat(item.profit)}}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="recordList.length > 0" class="pagination-box">
            <el-pagination small @current-change="handleCurrentChange"
                :current-page="currentPage" :pager-count="5"
                :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                :page-size="pageSize" layout="prev, pager, next"
                :total="total" background>
            </el-pagination>
        </div>
        <el-dialog
          :title="$t('PerpetualContract.ClosePosition')"
          :visible.sync="visible"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          width="calc(100% - 3.2rem)"
          top="30vh"
          center
          custom-class="close-dialog-h5"
          @close="close">
          <div class="input-box">
              <el-input v-model="num" type="number" :placeholder="$t('PerpetualContract.EnterCloseQuantity')"></el-input>
              <div class="all" @click="num = allNum">{{$t('Trade.All')}}</div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button :loading="btnLoading" type="primary" @click="closePosition">{{$t('Common.Confirm')}}</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showModal: false,
                profitPrice: 0,
                stopPrice: 0,
                maxStopPrice: 0,
                orderId: '',
                tabList: [this.$t('PerpetualContract.CurrentOrders'), this.$t('PerpetualContract.OrderHistory')],
                curIndex: 0,
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1,
                visible: false,
                num: '',
                btnLoading: false,
                productId: '',
                allNum: 0
            }
        },
        created() {
            this.init()
        },
        mounted() {
            this.$bus.$off('updatePerpetualOrder')
            this.$bus.$on('updatePerpetualOrder', () => {
                this.curIndex = 0
                this.init()
            })
        },
        computed: {
            perpetualOrder() {
                return this.$store.state.perpetualOrder
            },
            
            productList() {
                return this.$store.state.productList
            },
            
            curPrice() {
                return id => {
                    let price = 0
                    this.productList.forEach(item => {
                        if(id == item.id) {
                            price = parseFloat(item.price.close)
                        }
                    })
                    
                    return price
                }
            }
        },
        watch: {
            perpetualOrder(val) {
                if(this.curIndex == 0) {
                    let id_list = JSON.parse(JSON.stringify(val.map(item => item.id)))
                    this.recordList.map((item,index) => {
                        if(id_list.includes(item.id)) {
                            let cur_order = val.find(i => i.id == item.id)
                            item.number = cur_order.number
                            item.margin = cur_order.margin
                            item.service_fee = cur_order.service_fee
							item.latest_price = cur_order.latest_price
                            item.unsettled_profit = cur_order.unsettled_profit
                        } else {
                            this.recordList.splice(index,1)
                        }
                    })
                }
            }
        },
        methods: {
            Edit(id,profit_price,stop_price,open_price) {
                this.orderId = id
                this.profitPrice = profit_price
                this.stopPrice = stop_price
                this.maxStopPrice = open_price
                this.showModal = true
            },
            
            closeModal() {
                this.showModal = false
            },
            
            editOrder() {
                this.showModal = false
                this.btnLoading = true
                this.$api.changeOrderPrice({
                    id: this.orderId,
                    max_limit: this.profitPrice,
                    stop_limit: this.stopPrice
                }).then(res => {
                    this.btnLoading = false
                    this.$message({
                        message: res.message,
                        type: 'success',
                        duration: 1500
                    })
                    this.init()
                }).catch(err => {
                    this.btnLoading = false
                })
            },
            
            changeCurIndex(index) {
                if(this.curIndex != index) {
                    this.curIndex = index
                    this.init()
                }
            },
            
            init() {
                this.recordList = []
                this.currentPage = 1
                this.getRecordList(true)
            },
            
            getRecordList(bool=false) {
                this.$api.perpetualOrderRecord({
                    status: this.curIndex,
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            },
            
            openModal(id,number,close_number) {
                this.productId = id
                this.allNum = number - close_number
                this.visible = true
            },
            
            close() {
                this.num = ''
            },
            
            closePosition(id) {
                this.btnLoading = true
                this.$api.perpetualOrderClose({
                    id
                }).then(res => {
                    this.visible = false
                    this.btnLoading = false
                    this.$message({
                        message: res.message,
                        type: 'success',
                        duration: 1500
                    })
                    this.init()
                    this.$bus.$emit('updatePerpetualBalance')
                }).catch(err => {
                    this.btnLoading = false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .my-order {
        width: 100%;
        margin-top: 4px;
        
        .confirm-mask {
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 99;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100vh;
            box-sizing: border-box;
            padding: 0 1.6rem;
            background-color: rgba(0, 0, 0, .4);
            
            .modal {
                width: 100%;
                box-sizing: border-box;
                padding: 2rem 2rem 2.4rem;
                border-radius: 8px;
                background-color: #FFFFFF;
                
                .modal-title {
                    width: 100%;
                    margin-bottom: 2rem;
                    font-size: 1.7rem;
                    font-weight: bold;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .info-list {
                    width: 100%;
                    
                    .info-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        
                        &:not(:last-child) {
                            margin-bottom: 1.4rem;
                        }
                        
                        .label {
                            max-width: 48%;
                            font-size: 1.3rem;
                            color: #6D6F7E;
                            word-wrap: break-word;
                        }
                        
                        .value {
                            max-width: 48%;
                            font-size: 1.4rem;
                            font-weight: bold;
                            color: #000000;
                            word-break: break-all;
                        }
                        
                        /deep/ .el-input--small {
                            font-size: 12px;
                        }
                    }
                }
                
                .operate-btns {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 2.4rem;
                    
                    .cancel-btn {
                        width: 48%;
                        height: 3.6rem;
                        box-sizing: border-box;
                        padding: 0;
                        border: 1px solid #6D6F7E;
                        border-radius: 2px;
                        font-size: 1.3rem;
                        color: #6D6F7E;
                        text-align: center;
                        background-color: #FFFFFF;
                    }
                    
                    .confirm-btn {
                        width: 48%;
                        height: 3.6rem;
                        box-sizing: border-box;
                        padding: 0;
                        border: 0;
                        border-radius: 2px;
                        font-size: 1.3rem;
                        color: #FFFFFF;
                        text-align: center;
                        background-color: #5466EF;
                    }
                }
            }
        }
        
        .tab-list {
            display: flex;
            align-items: center;
            width: 100%;
            
            .tab-item {
                height: 3.5rem;
                line-height: 3.5rem;
                box-sizing: border-box;
                padding: 0 1.2rem;
                font-size: 1.3rem;
                font-weight: bold;
                color: #6D6F7E;
                text-align: center;
                white-space: nowrap;
                background-color: #F2F3F9;
                cursor: pointer;
            }
            
            .active {
                color: #5466EF !important;
                background-color: #FFFFFF !important;
            }
        }
        
        .record-list {
            width: 100%;
            
            .record-item {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 1.2rem;
                border-top: 1px solid #EBEEF5;
                background-color: #FFFFFF;
                
                .info-item {
                    width: 48%;
                    margin-left: 4%;
                    
                    &:nth-child(2n+1) {
                        margin-left: 0;
                    }
                    
                    &:nth-child(n+3) {
                        margin-top: 1.6rem;
                    }
                    
                    .label {
                        width: 100%;
                        margin-bottom: .8rem;
                        font-size: 1.2rem;
                        color: #6D6F7E;
                        word-wrap: break-word;
                    }
                    
                    .value {
                        width: 100%;
                        font-size: 1.2rem;
                        color: #000000;
                        word-break: break-all;
                    }
                    
                    .profit {
                        font-size: 1.5rem;
                        font-weight: bold;
                    }
                }
            }
        }
        
        .btns {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            
            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                min-height: 2.8rem;
                box-sizing: border-box;
                padding: 0.6rem;
                border: 0;
                margin: 0;
                border-radius: 2px;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                word-wrap: break-word;
                background-color: #5466EF;
                cursor: pointer;
                white-space: normal;
                
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }
        
        .pagination-box {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            box-sizing: border-box;
            padding: 1.2rem 0;
            
            /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                background-color: #5466EF;
            }
            
            /deep/ .el-pager li:not(.active):hover {
                color: #5466EF;
            }
        }
    }
</style>